package tripper.trips

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import io.ktor.http.*
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.getAndUpdate
import kotlinx.coroutines.flow.update
import org.lighthousegames.logging.KmLog
import org.lighthousegames.logging.logging
import tripper.LocalDependencies
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.rememberCoroutineScope
import tripper.domain.FileMeta
import tripper.domain.Url
import tripper.files.*

class ImagesViewModel(
  initial: List<FileMeta>,
  private val onUpload: (FileMeta) -> Unit,
  private val onDelete: (FileMeta) -> Unit,
  private val uploadingCount: MutableStateFlow<Int>,
  private val imageConverter: ImageConverter,
  private val fileClient: FileClient,
  private val scope: SafeCoroutineScope,
  private val log: KmLog = logging(ImagesViewModel::class.simpleName),
): FileViewModel {
  private val idPool = MutableStateFlow(0)
  private val _images = MutableStateFlow(initial.map { ImageState(idPool.nextId(), it.src, it) })
  val images = _images.asStateFlow()
  
  override fun upload(file: ByteArray, name: String, type: String) {
    uploadingCount.update { it + 1 }
    val imageId = idPool.nextId()
    scope.launch {
      try {
        val (compressedFile, newName, newType) = imageConverter.compress(FileData(file, name, ContentType.parse(type)))
          .onPreLoad { preLoadedUrl -> _images.update { it + ImageState(imageId, preLoadedUrl, null) } }
          .onCompressed { compressedUrl -> _images.update { it.map { if (it.id == imageId) it.copy(src = compressedUrl) else it } } }
          .result()

        val loadedImage = fileClient.upload(compressedFile, newName, newType)
        _images.update { it.map { if (it.id == imageId) it.copy(file = loadedImage) else it } }
        onUpload(loadedImage)
      } catch (e: Throwable) {
        log.error(e) { "Image upload failed" }
        _images.update { it.filter { it.id != imageId } }
      } finally {
        uploadingCount.update { it - 1 }
      }
    }
  }
  
  fun delete(image: ImageState) {
    if (image.file == null) return
    scope.launch {
      fileClient.delete(image.file)
      _images.update { it - image }
      onDelete(image.file)
    }
  }
  
  private fun MutableStateFlow<Int>.nextId() = getAndUpdate { it + 1 }
  
  data class ImageState(val id: Int, val src: Url, val file: FileMeta?) {
    val uploaded = file != null
  }
  
  companion object {
    @Composable
    fun remember(
      images: List<FileMeta>,
      onUpload: (FileMeta) -> Unit = {},
      onDelete: (FileMeta) -> Unit = {},
      uploadingCount: MutableStateFlow<Int> = MutableStateFlow(0),
      key: Any? = null,
    ): ImagesViewModel {
      val imageConverter = rememberImageConverter()
      val fileClient = LocalDependencies.current.fileClient
      val scope = rememberCoroutineScope()
      return remember(key) { ImagesViewModel(images, onUpload, onDelete, uploadingCount, imageConverter, fileClient, scope) }
    }
  }
}