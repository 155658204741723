package tripper.notifications

import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

open class NotificationViewModel {
  private val notificationInternal = MutableStateFlow<Notification?>(null)
  open val notification = notificationInternal.asStateFlow()
  
  open fun notify(text: String, duration: Duration = 2.seconds) {
    notificationInternal.update { Notification(text, duration) }
  } 
}