package tripper.auth.dto

import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tripper.users.FullUser

@Serializable
sealed interface Provider {
  val id: String

  @Serializable
  @SerialName("vk")
  data class Vk(override val id: String): Provider

  @Serializable
  @SerialName("google")
  data class Google(override val id: String): Provider

  @Serializable
  @SerialName("tripline")
  data class Tripline(override val id: String): Provider
}

@Serializable
data class VkAuth(
  val user: FullUser,
  val signature: String,
  val expire: Instant,
  val accessToken: String,
  val secret: String,
)