package tripper

import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update

open class RequestViewModel {
  private val activeRequestsInternal = MutableStateFlow(emptySet<Request>())
  open val activeRequests = activeRequestsInternal.asStateFlow()
  
  open fun requestStarted(request: Request) {
    activeRequestsInternal.update { it + request }
  }

  open fun requestFinished(request: Request) {
    activeRequestsInternal.update { it - request }
  }
}

data class Request(val id: String)