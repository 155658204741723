package tripper

import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.AttrsScope

fun AttrsScope<*>.pressAnimation(pressed: Boolean, setPressed: (Boolean) -> Unit) {
  if (pressed) {
    style { property("scale", ".9") }
  }
  val onMouseUp = { setPressed(false) }
  onMouseDown {
    setPressed(true)
    it.preventDefault()
    window.addEventListener("mouseup", { onMouseUp() }, json { once = true })
  }
  onMouseUp { onMouseUp() }
}