package tripper.followings

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

class FollowingService(private val client: HttpClient) {
  
  suspend fun save(following: Following): Following {
    val newId = client.post("followings") { setBody(following) }.body<String>()
    return following.copy(rawId = newId)
  }

  suspend fun delete(following: Following) {
    client.delete("followings/${following.id}")
  }
  
  suspend fun followings(): List<Following> = client.get("followings").body()
}