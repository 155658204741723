package tripper

import kotlinx.browser.localStorage
import org.w3c.dom.get
import org.w3c.dom.set

class JsLocalStorage(private val storage: org.w3c.dom.Storage = localStorage): Storage {
  override fun get(key: Storage.Key): String? = storage[key.name]

  override fun set(key: Storage.Key, value: String) {
    storage[key.name] = value
  }

  override fun remove(key: Storage.Key) {
    storage.removeItem(key.name)
  }
}