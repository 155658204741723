package tripper.users

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import kotlinx.coroutines.flow.*
import tripper.Loading
import tripper.Loading.Finished
import tripper.Loading.None
import tripper.LocalDependencies
import tripper.NotFoundException
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.rememberCoroutineScope
import tripper.load
import kotlin.time.Duration.Companion.milliseconds

class ProfileViewModel(
  userRef: UserRef,
  private val userService: UserService,
  private val scope: SafeCoroutineScope,
) {
  private val _user = MutableStateFlow<Loading<User>>(None)
  private val _nicknameTaken = MutableStateFlow<Loading<Boolean>>(Finished(false))
  private val _edit = MutableStateFlow(false)
  val avatarLoading = MutableStateFlow(false)
  val user = _user.asStateFlow()
  val nicknameTaken = _nicknameTaken.asStateFlow()
  val edit = _edit.asStateFlow()
  
  init {
    scope.launch { 
      _user.load { userService.user(userRef) }
    }
    scope.launch {
      user.filterIsInstance<Finished<User>>()
        .mapNotNull { it.value }
        .distinctUntilChanged { old, new -> old.nickname == new.nickname }
        .debounce(300.milliseconds)
        .collectLatest { user ->
          user.nickname?.let { nickname ->
            _nicknameTaken.load {
              try {
                userService.user(nickname).id != user.id
              } catch (_: NotFoundException) { false }
            }
          }
        }
    }
  }
  
  fun setUser(user: User) {
    _user.update { Finished(user) }
  }
  
  fun setEdit(edit: Boolean) {
    _edit.update { edit }
  }
  
  fun save(user: User) {
    scope.launch {
      val nicknameTaken = nicknameTaken.filterIsInstance<Finished<Boolean>>().first().value
      if (nicknameTaken) return@launch
      avatarLoading.first { isLoading -> !isLoading }
      _user.load {
        userService.update(user)  
        user
      }
      _edit.update { false }
    }
  }

  companion object {
    @Composable
    fun create(userRef: UserRef): ProfileViewModel {
      val scope = rememberCoroutineScope()
      val userService = LocalDependencies.current.userService
      return remember(userRef) { ProfileViewModel(userRef, userService, scope) }
    }
  }
}