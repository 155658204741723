package tripper.navigation

import io.ktor.http.*
import kotlinx.browser.document
import org.w3c.dom.Window

class JsNavigation(private val window: Window = kotlinx.browser.window): PlatformNavigation {
  override val currentUrl get() = Url(window.location.pathname + window.location.search + window.location.hash)

  override fun url(path: Url) {
    window.history.pushState(null, document.title, path.stringify())
  }

  override fun inlineUrl(path: Url) {
    window.history.replaceState(null, document.title, path.stringify())
  }

  override fun back() {
    window.history.back()
  }

  override fun subscribeBack(onBack: () -> Unit) {
    window.addEventListener("popstate", {
      onBack()
    })
  }

  private fun Url.stringify(): String = buildString {
    append(fullPath)
    if (encodedFragment.isNotEmpty()) append("#$encodedFragment")
  }
}