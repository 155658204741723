package tripper.navigation

import io.ktor.http.*
import kotlinx.coroutines.flow.*
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.mapState

open class NavigatorViewModel(private val navigation: PlatformNavigation, scope: SafeCoroutineScope) {
  private val _url = MutableStateFlow(navigation.currentUrl)
  open val url = _url.asStateFlow()
  open val anchor = _url.mapState { it.fragment }
  
  init {
    navigation.subscribeBack {
      _url.update { navigation.currentUrl }
    }
    scope.launch {
      url
        .filter { it != navigation.currentUrl }
        .collectLatest {
          navigation.url(it)
        }
    }
  }
  
  open fun path(path: String) {
    _url.update { Url(path) }
  }
  
  open fun anchor(anchor: String) {
    _url.update { url -> url.update { fragment = anchor } }
  }

  open fun back() {
    navigation.back()
  }

  open fun inlinePath(path: String) {
    navigation.inlineUrl(Url(path))
  }

  private fun Url.update(builder: URLBuilder.() -> Unit): Url {
    return URLBuilder(this).apply(builder).build()
  }
}