package tripper.auth

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.client.request.*
import tripper.auth.dto.AccessGrant
import tripper.auth.dto.VkAuth

open class AuthClient(private val httpClient: HttpClient, private val authViewModel: AuthViewModel) {
  
  open suspend fun loginVk(auth: VkAuth) {
    val grant = httpClient.post("auth/login/vk") { setBody(auth) }.body<AccessGrant>()
    login(grant)
  }

  open suspend fun loginGoogle(token: String) {
    val grant = httpClient.post("auth/login/google") { setBody(token) }.body<AccessGrant>()
    login(grant)
  }

  private fun login(grant: AccessGrant) {
    httpClient.plugin(Auth).providers
      .filterIsInstance<BearerAuthProvider>()
      .first().clearToken()
    
    authViewModel.login(grant.accessToken)
  }

  open suspend fun logout() {
    httpClient.post("auth/logout")
    authViewModel.logout()
  }
}