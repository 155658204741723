package tripper.domain

import kotlinx.serialization.Serializable

@Serializable
data class Location(
  val coords: Coords,
  val displayName: String = "",
  val city: String? = null,
  val country: String? = null,
)
