package tripper.lib.google

import androidx.compose.runtime.NoLiveLiterals
import org.w3c.dom.HTMLElement
import tripper.Config
import tripper.json

@NoLiveLiterals
open class GoogleAuth {
  
  open fun render(element: HTMLElement, onLogin: (String) -> Unit) {
    google.accounts.id.initialize(json {
      client_id = Config.googleClientId
      callback = { response: CredentialResponse ->
        // TODO: If not authenticated
        onLogin(response.credential)
      }
    })
    google.accounts.id.renderButton(element, json { type = "icon"; size = "medium" })
  }
}