package tripper.coroutines

import kotlinx.coroutines.Deferred
import kotlinx.coroutines.withTimeout
import kotlin.time.Duration

suspend fun <T> Deferred<T>.await(timeout: Duration) {
  withTimeout(timeout) {
    await()
  }
}