package tripper.domain

import kotlinx.serialization.Serializable

@Serializable
data class Coords(
  //todo rename to lat lon
  val latitude: Double,
  val longitude: Double,
)
