package tripper.trips

import androidx.compose.runtime.*
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Text
import tripper.*
import tripper.components.*
import tripper.dadata.MOSKOW
import tripper.domain.Coords
import tripper.domain.FileMeta
import tripper.domain.WayPoint
import tripper.dto.Access
import tripper.lib.ymaps.YandexMap
import tripper.lib.ymaps.YandexMapHandler
import tripper.lib.ymaps.rememberYandexMapHandler
import tripper.navigation.Pages
import tripper.users.User
import tripper.validation.FieldValidationViewModel
import tripper.validation.Restrictions.Trips
import tripper.validation.rememberFieldValidation

@Composable
fun EditTrip(
  id: String?,
  author: User,
  pages: Pages = LocalDependencies.current.pages,
  viewModel: TripViewModel = TripViewModel.create(id, onSave = {
    pages.trip.open(it, false)
  }, onDelete = {
    pages.home.open()
  }),
) { 
  val tripLoading by viewModel.trip.collectAsState()
  val trip = tripLoading.untilLoaded(Trip(authorId = author.id, wayPoints = listOf(WayPoint())))
  EditTrip(trip, viewModel, isLoaded = tripLoading is Loading.Finished)
}

@Composable
fun EditTrip(
  trip: Trip,
  viewModel: TripViewModel = TripViewModel.create(),
  isLoaded: Boolean = true,
  yandexMapHandler: YandexMapHandler? = rememberYandexMapHandler(
    editable = true,
    onPointDragEnd = viewModel::geocodeWayPointLocation,
  ),
  messages: Messages = rememberMessages(),
) {
  var preview by remember { mutableStateOf(false) }
  val label = when {
    isMobile() -> null
    preview -> messages.previewTrip()
    trip.rawId != null -> messages.editTrip() 
    else -> messages.newTrip()
  }
  val fieldValidation = rememberFieldValidation()
  val wayPoints = trip.wayPoints
  var addWayPointMode by remember(isLoaded, wayPoints.any { it.location == null }) {
    mutableStateOf(isLoaded && wayPoints.any { it.location == null }) 
  }
  val onAddWayPointClick: TripCardScope.() -> Unit = {
    addWayPointMode = true
    viewModel.addWayPoint()
    scrollToPointIndex = viewModel.trip.value.map { it.wayPoints.lastIndex }.untilLoaded(0)
  }
  TripPage(label, card = {
    if (preview) TripCardView(trip, short = false, controls = EditControls(trip, fieldValidation, preview, { preview = it }, onAddWayPointClick, addWayPointMode, viewModel))
    else {
      TripCard(trip, modifier { classes("edit") }, 
        map = map@{
          if (yandexMapHandler == null) return@map
          var afterLoading by remember { mutableStateOf(false) }
          //todo detect current user city
          YandexMap(yandexMapHandler, Coords(MOSKOW.latitude, MOSKOW.longitude), MOSKOW.zoom, wayPoints, 
            onPointClick = { scrollToPointIndex = it },
            onSearchResult = { location -> viewModel.updateWayPoint(focusedPointIndex) { it.copy(location = location) } }
          )
          remember(wayPoints.mapNotNull { it.location }) {
            yandexMapHandler.setRoute(wayPoints, focusedPointIndex, applyBounds = isLoaded && !afterLoading)
          }
          remember(focusedPointIndex, wayPoints.count { it.location != null }) {
            yandexMapHandler.focusPoint(focusedPointIndex)
            if (afterLoading) wayPoints.getOrNull(focusedPointIndex)?.location?.let { yandexMapHandler.panTo(it.coords) }
            yandexMapHandler.clearSearch()
            if (isLoaded) afterLoading = true
          }
          remember(addWayPointMode) {
            if (addWayPointMode) {
              yandexMapHandler.addPointMode(onAddPoint = { index, coords ->
                viewModel.geocodeWayPointLocation(index, coords)
                addWayPointMode = false
              })
            }
          }
        },
        header = {
          TextField(
            trip.title,
            onValueChange = { viewModel.setTrip(trip.copy(title = it)) },
            validation = fieldValidation.ofField(Trip::title),
            placeholder = messages.title().markRequired(),
            maxLength = Trips.titleMaxLength,
            modifier = modifier { classes("title") },
          )
          Row(modifier { classes("access") }) {
            var accessExpanded by remember { mutableStateOf(false) }
            Field(modifier { classes("labeled") }) {
              Label("access") { Text(messages.access()) }
              Button({
                id("access")
                onClick { accessExpanded = !accessExpanded }
              }) {
                Text(messages.accessOption(trip.access))
              }
            }
            DropdownMenu(accessExpanded, onDismissRequest = { accessExpanded = false }) {
              Access.entries.forEach { access ->
                if (access != trip.access) {
                  DropdownMenuItem(modifier {
                    onClick {
                      viewModel.setTrip(trip.copy(access = access))
                      accessExpanded = false
                    }
                  }) {
                    Text(messages.accessOption(access))
                  }
                }
              }
            }
          }
          if (trip.rawId != null) {
            GoogleIcon(Icons.Delete, modifier {
              classes("delete")
              onClick { viewModel.delete(trip) }
            }, messages.deleteTrip())
          }
        },
        summary = {
          TextField(
            trip.summary.orEmpty(),
            onValueChange = { viewModel.setTrip(trip.copy(summary = it)) },
            validation = fieldValidation.ofField(Trip::summary),
            label = messages.summary(),
            singleLine = false,
            minLines = 2,
            modifier = modifier { classes("summary") },
            maxLength = Trips.summaryMaxLength,
          )
        },
        route = {
          Route(wayPoints, short = false) { index, wayPoint ->
            WayPointEdit(
              wayPoint,
              setWayPoint = { updatedWayPoint -> viewModel.updateWayPoint(index) { updatedWayPoint } },
              onRemove = {
                yandexMapHandler?.removePoint(index)
                viewModel.removeWayPoint(index)
                if (focusedPointIndex >= index) focusedPointIndex = (index - 1).coerceAtLeast(0)
              },
              imagesViewModel = viewModel.imagesViewModel(index, wayPoint.images),
              fieldValidation.ofPath { it[Trip::wayPoints][index] },
            )
          }
        },
        controls = EditControls(trip, fieldValidation, preview, { preview = it }, onAddWayPointClick, addWayPointMode, viewModel),
      )
    }
  })
}

@Composable
fun EditControls(
  trip: Trip,
  fieldValidation: FieldValidationViewModel = rememberFieldValidation(),
  preview: Boolean = false,
  setPreview: (Boolean) -> Unit = {},
  onAddWayPointClick: TripCardScope.() -> Unit = {},
  addWayPointMode: Boolean = false,
  viewModel: TripViewModel = TripViewModel.create(),
  messages: Messages = rememberMessages(),
): List<@Composable TripCardScope.() -> Unit> = buildList {
  if (preview) {
    add {
      Button({
        classes("secondary")
        onClick { setPreview(false) }
      }) {
        Text(messages.edit())
      }
    }
  } else {
    add {
      Button({
        classes("secondary")
        onClick {
          onAddWayPointClick()
        }
        if (addWayPointMode || trip.wayPoints.size >= Trips.wayPointsMaxNumber) {
          title(
            if (addWayPointMode) messages.specifyLocation() 
            else messages.maxWayPoints(Trips.wayPointsMaxNumber)
          )
          disabled()
        }
      }) {
        Text(messages.addWayPoint())
      }
    }
    add {
      Button({
        classes("secondary")
        onClick {
          fieldValidation.validate { trip(trip) }
          setPreview(true)
        }
      }) {
        Text(messages.preview())
      }
    }
  }
  
  add {
    Button({
      classes("save", "primary")
      onClick {
        fieldValidation.validate { trip(trip) }
        viewModel.save(trip)
      }
    }) {
      Text(messages.save())
    }
  }
}

@Composable
private fun TripViewModel.imagesViewModel(index: Int, images: List<FileMeta>): ImagesViewModel {
  return ImagesViewModel.remember(
    images,
    onUpload = { image -> updateWayPoint(index) { it.copy(images = it.images + image) } },
    onDelete = { imageUrl -> updateWayPoint(index) { it.copy(images = it.images.filter { it != imageUrl }) } },
    uploadingImagesCount,
    key = index,
  )
}