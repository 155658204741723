package tripper.components

import androidx.compose.runtime.*
import tripper.Modifier
import tripper.emptyModifier
import tripper.lib.js.IntersectionObserver

@Composable
fun LazyColumn(modifier: Modifier = emptyModifier(), content: @Composable ColumnScope.() -> Unit) {
  var init by remember { mutableStateOf(false) }
  val observer = remember {
    IntersectionObserver({ entries, observer ->
      entries.forEach {
        if (it.isIntersecting) {
          init = true
          observer.unobserve(it.target)
        }
      }
    })
  }
  DisposableEffect(Unit) {
    onDispose { observer.disconnect() }
  }
  Column(modifier) {
    if (init) content()
    DisposableEffect(Unit) {
      observer.observe(scopeElement)
      onDispose { observer.unobserve(scopeElement) }
    }
  }
}