package tripper.components

import androidx.compose.material3.DrawerValue.Closed
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.NoLiveLiterals
import androidx.compose.runtime.remember
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import tripper.*
import tripper.auth.AuthAction
import tripper.auth.AuthAction.Logout
import tripper.auth.AuthViewModel
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.rememberCoroutineScope
import tripper.lib.google.GoogleAuth
import tripper.lib.vk.VkAuth
import tripper.navigation.Pages
import tripper.search.SearchInput
import tripper.users.ProfileBody

@NoLiveLiterals
@Composable
fun Header(
  page: Pages.Page,
  pages: Pages = LocalDependencies.current.pages,
  scope: SafeCoroutineScope = rememberCoroutineScope(),
  messages: Messages = rememberMessages(),
) = Div({ classes("header") }) {
  if (isMobile()) {
    MobileHeader(page, pages, messages, scope)
  } else {
    DesktopHeader(pages, messages)
  }
}

@Composable
private fun MobileHeader(
  page: Pages.Page,
  pages: Pages,
  messages: Messages,
  scope: SafeCoroutineScope,
) {
  NavigationDrawer(page, messages, pages, scope)
  Spacer(style { width(1.cssRem) })
  Link(pages.home.path(), modifier { classes("logo") }) {
    Text(messages.tipTrip())
  }
  Spacer(style { width(1.cssRem) })
  SearchInput()
  if (rememberIsLoggedIn()) {
    Spacer(style { width(1.cssRem) })
    Link(pages.createTrip.path()) {
      GoogleIcon(Icons.Add, modifier {
        classes("create-trip")
      })
    }
  }
  Spacer(style { width(1.cssRem) })
  AuthPanel()
}

@Composable
private fun DesktopHeader(
  pages: Pages,
  messages: Messages,
) {
  Link(pages.home.path(), modifier { classes("logo") }) {
    Text(messages.tipTrip())
  }
  Spacer(style { flexGrow(1) })
  SearchInput()
  
  if (rememberIsLoggedIn()) {
    Spacer(style { width(1.cssRem) })
    Link(pages.followings.path(), modifier { classes("followings") }) {
      Text(messages.followings())
    }
    Spacer(style { width(1.cssRem) })
    Tooltip(tooltip = {
      Text(messages.createTrip())
    }) {
      Link(pages.createTrip.path()) {
        GoogleIcon(Icons.Add, modifier {
          classes("create-trip")
        })
      }
    }
  }
  Spacer(style { flexGrow(1) })
  LanguagePicker()
  Spacer(style { width(1.cssRem) })
  AuthPanel()
}

@Composable
fun NavigationDrawer(
  page: Pages.Page,
  messages: Messages = rememberMessages(),
  pages: Pages = LocalDependencies.current.pages,
  scope: SafeCoroutineScope = rememberCoroutineScope(),
  authViewModel: AuthViewModel = LocalDependencies.current.authViewModel,
  googleAuth: GoogleAuth = remember { GoogleAuth() },
  vkAuth: VkAuth? = VkAuth.create(),
) {
  val drawerState = rememberDrawerState(Closed)
  val self = rememberSelfLoading().untilLoaded(null)
  ModalNavigationDrawer(drawerContent = {
    ModalDrawerSheet {
      Row(modifier { classes("header") }) {
        if (self != null) {
          Row(modifier { classes("profile-card") }) {
            ProfileBody(
              self,
              modifier {
                onClick {
                  pages.profile.open(self)
                  scope.launch { drawerState.close() }
                }
              },
              showAbout = false,
            )
          }
          Divider()
        }
        GoogleIcon(Icons.Close, modifier {
          classes("close")
          onClick {
            scope.launch { drawerState.close() }
          }
        })
      }
      NavigationDrawerItem({ Text(messages.trips()) }, selected = page == pages.trips, onClick = {
        pages.trips.open()
        scope.launch { drawerState.close() }
      }, icon = {
        GoogleIcon(Icons.Travel)
      })
      if (self != null) NavigationDrawerItem({ Text(messages.followings()) }, selected = page == pages.followings, onClick = {
        pages.followings.open()
        scope.launch { drawerState.close() }
      }, icon = {
        GoogleIcon(Icons.YourTrips)
      })
      if (self != null) NavigationDrawerItem({ Text(messages.favorites()) }, selected = page == pages.favorites, onClick = {
        pages.favorites.open()
        scope.launch { drawerState.close() }
      }, icon = {
        GoogleIcon(Icons.Star)
      })
      if (self != null) NavigationDrawerItem({ Text(messages.createTrip()) }, selected = page == pages.createTrip, onClick = {
        pages.createTrip.open()
        scope.launch { drawerState.close() }  
      }, icon = {
        GoogleIcon(Icons.Add)
      })
      Spacer(style { flexGrow(1) })
      Row(modifier { classes("footer") }) {
        LanguagePicker()
        if (self != null) {
          Column(modifier {
            classes("logout")
            onClick {
              authViewModel.onAction(Logout)
              scope.launch { drawerState.close() }
            }
          }) {
            GoogleIcon(Icons.Logout)
            Text(messages.logout())
          }
        } else {
          Column(modifier { classes("login-menu") }) {
            if (vkAuth != null) {
              Img("/public/vk.svg") {
                classes("vk")
                onClick { vkAuth.login() }
              }
            }
            Div({ classes("google") }) {
              DisposableEffect(Unit) {
                googleAuth.render(scopeElement, onLogin = { authViewModel.onAction(AuthAction.LoginGoogle(it)) })

                onDispose { }
              }
            }
          }
        }
      }
    }
  }, drawerState) {
    GoogleIcon(Icons.Menu, modifier {
      classes("open")
      onClick { scope.launch { drawerState.open() } }
    })
  }
}