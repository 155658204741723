package tripper.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.A
import tripper.LocalDependencies
import tripper.Modifier
import tripper.emptyModifier
import tripper.navigation.NavigatorViewModel

@Composable
fun Link(
  link: String,
  modifier: Modifier = emptyModifier(),
  navigator: NavigatorViewModel = LocalDependencies.current.navigatorViewModel,
  content: @Composable () -> Unit,
) {
  A(link, {
    classes("link")
    onClick {
      navigator.path(link)
      it.preventDefault()
    }
    modifier.modify(this)
  }) {
    content()
  }
}