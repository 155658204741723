package tripper.trips

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.update
import tripper.Loading
import tripper.Loading.Finished
import tripper.Loading.None
import tripper.LocalDependencies
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.rememberCoroutineScope
import tripper.domain.Coords
import tripper.domain.Location
import tripper.domain.WayPoint
import tripper.geocoding.GeocodingService
import tripper.load
import tripper.map

class TripViewModel(
  private val onSave: (Trip) -> Unit,
  private val onDelete: () -> Unit,
  private val service: TripService,
  private val geocodingService: GeocodingService,
  private val scope: SafeCoroutineScope,
) {
  private val _trip = MutableStateFlow<Loading<Trip>>(None)
  val uploadingImagesCount = MutableStateFlow(0)
  val trip = _trip.asStateFlow()
  
  fun setTrip(trip: Trip) {
    _trip.update { Finished(trip) }
  }
  
  fun load(id: String) {
    scope.launch {
      _trip.load { service.trip(id) }
    }
  }
  
  fun save(trip: Trip) {
    scope.launch {
      awaitImagesLoading()
      onSave(service.save(trip))
    }
  }

  fun delete(trip: Trip) {
    scope.launch { 
      service.delete(trip)
      onDelete()
    }
  }

  fun updateWayPoint(index: Int, block: (WayPoint) -> WayPoint) {
    updateWayPoints {
      if (index !in 0..<it.size) return@updateWayPoints
      it[index] = block(it[index]) 
    }
  }
  
  fun geocodeWayPointLocation(index: Int, coords: Coords) {
    updateWayPoint(index) { it.copy(location = Location(coords)) }
    geocodeLocation(index, coords)
  }
  
  fun addWayPoint() {
    updateWayPoints { wayPoints -> 
      wayPoints += WayPoint() 
    }
  }
  
  fun removeWayPoint(index: Int) {
    updateWayPoints { wayPoints ->
      wayPoints.removeAt(index)
      if (wayPoints.isEmpty()) wayPoints += WayPoint()
    }
  }
  
  private suspend fun awaitImagesLoading() {
    uploadingImagesCount.first { it == 0 }
  }

  private fun updateWayPoints(block: (MutableList<WayPoint>) -> Unit) {
    _trip.update { tripLoading ->
      tripLoading.map { trip ->
        val wayPoints = trip.wayPoints.toMutableList()
        block(wayPoints)
        trip.copy(wayPoints = wayPoints)
      }
    }
  }

  private fun geocodeLocation(wayPointIndex: Int, coords: Coords) {
    scope.launch {
      val location = geocodingService.geocode(coords)
      updateWayPoint(wayPointIndex) { it.copy(location = location) }
    }
  }

  companion object {
    @Composable
    fun create(id: String?, onSave: (Trip) -> Unit = {}, onDelete: () -> Unit = {}): TripViewModel {
      val viewModel = create(onSave, onDelete)
      if (id != null) remember { viewModel.load(id) }
      return viewModel
    }

    @Composable
    fun create(onSave: (Trip) -> Unit = {}, onDelete: () -> Unit = {}): TripViewModel {
      val tripService = LocalDependencies.current.tripService
      val scope = rememberCoroutineScope()
      val geocodingService = LocalDependencies.current.geocodingService
      return remember { TripViewModel(onSave, onDelete, tripService, geocodingService, scope) }
    }
  }
}