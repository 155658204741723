package tripper

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import tripper.Loading.Null
import kotlin.coroutines.coroutineContext
import kotlin.time.Duration
import kotlin.time.Duration.Companion.ZERO

sealed interface Loading<out T> {
  data object None: Loading<Nothing>
  data class Debounce<T>(val job: Job, val current: Any? = Null): Loading<T>
  data class InProgress<T>(val current: Any? = Null): Loading<T>
  data class Finished<T>(val value: T): Loading<T>
  
  data object Null
}

inline fun <T> Loading<T>.onLoaded(block: (T) -> Unit) {
  when(this) {
    is Loading.Finished<T> -> block(value)
    is Loading.InProgress<T> -> if (current != Null) block(current as T)
    is Loading.Debounce -> if (current != Null) block(current as T)
    is Loading.None -> {}
  }
}

inline fun <T, R> Loading<T>.map(block: (T) -> R): Loading<R> {
  return when(this) {
    is Loading.Finished<T> -> Loading.Finished(block(value))
    is Loading.InProgress<T> -> if (current != Null) Loading.InProgress(block(current as T)) else Loading.InProgress()
    is Loading.Debounce -> if (current != Null) Loading.Debounce(job, block(current as T)) else Loading.Debounce(job)
    is Loading.None -> Loading.None
  }
}

fun <T> Loading<T>.untilLoaded(fallback: T) = untilLoaded { fallback }

inline fun <T> Loading<T>.untilLoaded(fallback: () -> T) = when(this) {
  is Loading.Finished<T> -> value
  is Loading.InProgress -> if (current != Null) current as T else fallback()
  is Loading.Debounce -> if (current != Null) current as T else fallback()
  is Loading.None -> fallback()
}

suspend fun <T> MutableStateFlow<Loading<T>>.load(debounce: Duration = ZERO, block: suspend (Loading<T>) -> T) {
  var debounceJob: Job? = null
  if (debounce > ZERO) debounceJob = debounce(debounce, block)
  val currentValue = value
  update {
    when (it) {
      is Loading.None -> if (debounceJob != null) Loading.Debounce(debounceJob) else Loading.InProgress()
      is Loading.InProgress -> return
      is Loading.Debounce -> {
        if (debounceJob != null) {
          it.job.cancel()
          Loading.Debounce(debounceJob)
        } 
        else Loading.InProgress()
      }
      is Loading.Finished -> if (debounceJob != null) Loading.Debounce(debounceJob) else Loading.InProgress(it.value)
    }
  }
  
  if (debounceJob == null) {
    try {
      val result = block(currentValue)
      update { Loading.Finished(result) }
    } catch (e: Throwable) {
      update { currentValue }
      throw e
    }
  }
}

private suspend fun <T> MutableStateFlow<Loading<T>>.debounce(
  debounce: Duration,
  block: suspend (Loading<T>) -> T,
): Job = CoroutineScope(coroutineContext).launch {
  delay(debounce)
  load(ZERO, block)
}

fun <T> Loading<List<T>>.orEmpty(): List<T> = untilLoaded(emptyList())

inline fun <T> Loading<T>.fold(
  onLoaded: (T) -> Unit = {},
  onNone: () -> Unit = {},
) = when(this) {
  is Loading.Finished -> onLoaded(value)
  is Loading.InProgress -> if (current != Null) onLoaded(current as T) else onNone()
  is Loading.Debounce -> if (current != Null) onLoaded(current as T) else onNone()
  is Loading.None -> onNone()
}

