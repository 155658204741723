package tripper.lib

import kotlinx.coroutines.await
import org.lighthousegames.logging.logging
import tripper.Config
import tripper.lib.vk.AuthParameters
import tripper.lib.vk.VK
import tripper.lib.ymaps.ymaps
import kotlin.js.Promise

object Libs {
  private val log = logging(Libs::class.simpleName)
  private var ymap: Promise<Any>? = null
  
  fun init() {
    try {
      VK.init(object : AuthParameters {
        override val apiId = Config.vkAppId
      })
      ymap = ymaps.ready()
    } catch (e: Throwable) {
      log.error(e) { "Libs init error" }
    }
  }
  
  suspend fun ymapsAwait() {
    ymap?.await()
  }

  fun ymapsAvailable() = ymap != null
}