package tripper.likes

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import tripper.Loading
import tripper.Loading.None
import tripper.LocalDependencies
import tripper.comments.ContentId
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.rememberCoroutineScope
import tripper.load
import tripper.untilLoaded
import tripper.users.UserRef

class LikeViewModel(
  private val contentId: ContentId,
  private val userId: UserRef.Id,
  private val service: LikeService,
  private val scope: SafeCoroutineScope,
) {
  private val _like = MutableStateFlow<Loading<Like?>>(None)
  val like = _like.asStateFlow()
  
  init {
    scope.launch { 
      _like.load { service.like(contentId) }
    }
  }
  
  fun likeContent() {
    scope.launch { 
      _like.load { service.save(Like(contentId, userId)) }
    }
  }
  
  fun dislike() {
    val like = like.value.untilLoaded(null) ?: return
    scope.launch { 
      _like.load {
        service.delete(like)
        null
      }
    }
  }
  
  companion object {
    @Composable
    fun remember(contentId: ContentId, userId: UserRef.Id): LikeViewModel {
      val likeService = LocalDependencies.current.likeService
      val scope = rememberCoroutineScope()
      return remember { LikeViewModel(contentId, userId, likeService, scope) }
    }
  }
}