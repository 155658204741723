package tripper.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import kotlinx.browser.window
import org.lighthousegames.logging.KmLog
import org.lighthousegames.logging.logging
import org.w3c.dom.ErrorEvent
import org.w3c.dom.events.Event
import tripper.ApplicationException
import tripper.LocalDependencies
import tripper.Messages
import tripper.notifications.NotificationViewModel
import tripper.rememberMessages
import tripper.validation.FieldValidationException

@Composable
fun GlobalExceptionHandler(
  notificationViewModel: NotificationViewModel = LocalDependencies.current.notificationViewModel,
  messages: Messages = rememberMessages(),
  log: KmLog = logging("GlobalExceptionHandler"),
) {
  DisposableEffect(Unit) {
    val handler: (Event) -> Unit = {
      (it as ErrorEvent)
      it.preventDefault()

      when (it.error) {
        is FieldValidationException -> {}
        is ApplicationException -> notificationViewModel.notify((it.error as ApplicationException).message ?: messages.somethingWentWrongError())
        else -> log.error(it.error as Throwable?) { it.message }
      }
    }
    
    window.addEventListener("error", handler)
    onDispose { window.removeEventListener("error", handler) }
  }
}