package tripper.components

import androidx.compose.runtime.*
import org.jetbrains.compose.web.dom.Div
import tripper.dto.Locale
import tripper.json
import tripper.lib.toastui.Editor
import tripper.lib.toastui.EditorOptions
import tripper.rememberLocale

@Composable
fun MarkdownEditor(
  markdown: String,
  onChange: (String) -> Unit,
  placeholder: String? = null,
  locale: Locale = rememberLocale(),
  createEditor: (EditorOptions) -> Editor = { Editor (it) },
) = Div({ classes("editor") }) {
  val currentOnChange by rememberUpdatedState(onChange)
  val (editor, setEditor) = remember { mutableStateOf<Editor?>(null) }
  DisposableEffect(Unit) {
    val editor = createEditor(json {
      el = scopeElement
      initialValue = markdown
      initialEditType = "wysiwyg"
      language = locale.languageTag
      usageStatistics = false
      height = "unset"
      minHeight = "300px"
      autofocus = false
      this.placeholder = placeholder
    })
    editor.removeToolbarItem("image")
    editor.on("change") { currentOnChange(editor.getMarkdown()) }
    editor.off("needChangeMode")
    editor.on("needChangeMode") { editor.changeMode(it, withoutFocus = true) }
    setEditor(editor)
    onDispose { editor.destroy() }
  }
  if (editor != null) {
    remember(markdown) {
      if (markdown != editor.getMarkdown()) {
        editor.setMarkdown(markdown, cursorToEnd = false)
      }
    }
  }
}