package tripper.search

import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.channels.consumeEach
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import tripper.Loading
import tripper.Loading.None
import tripper.coroutines.SafeCoroutineScope
import tripper.domain.Fields
import tripper.domain.Pagination
import tripper.domain.Sort.RELEVANT
import tripper.load
import tripper.untilLoaded

class SearchViewModel(
  private val service: SearchService,
  private val scope: SafeCoroutineScope,
) {
  private val invokeSearch = Channel<String>()
  private val _results = MutableStateFlow<Loading<List<SearchResult>>>(None)
  val results = _results.asStateFlow()
  
  fun search(query: String) {
    scope.launch { _results.load { service.search(query, pagination()) } }
  }
  
  fun invokeSearch(query: String) {
    scope.launch { invokeSearch.send(query) }
  }
  
  fun onInvokeSearch(block: (String) -> Unit) {
    scope.launch { invokeSearch.consumeEach(block) }
  } 
  
  fun loadMore(query: String, page: Int) {
    scope.launch {
      val currentResults = results.value.untilLoaded(emptyList())
      _results.load { (currentResults + service.search(query, pagination(page))).distinctBy { it.searchKey } }
    }
  }
  
  private fun pagination(page: Int = 0) = Pagination(Fields(page = page), sort = RELEVANT, _limit = 20)
}