package tripper.comments

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import tripper.Loading
import tripper.Loading.None
import tripper.LocalDependencies
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.rememberCoroutineScope
import tripper.domain.Fields
import tripper.domain.Pagination
import tripper.domain.Sort.RECENT
import tripper.load
import tripper.orEmpty
import tripper.users.User
import tripper.users.UserRef
import tripper.users.UserService
import tripper.validation.Restrictions.Requests

class CommentViewModel(
  private val contentId: ContentId,
  private val commentService: CommentService,
  private val userService: UserService,
  private val scope: SafeCoroutineScope,
) {
  private val _comments = MutableStateFlow<Loading<List<Comment>>>(None)
  private val _authors = MutableStateFlow<Map<UserRef.Id, User>>(emptyMap())
  private val _newComment = MutableStateFlow("")
  private val _hasMore = MutableStateFlow(false)
  val comments = _comments.asStateFlow()
  val authors = _authors.asStateFlow()
  val commentText = _newComment.asStateFlow()
  val hasMore = _hasMore.asStateFlow()
  
  init {
    scope.launch {
      _comments.load { loadComments() }
    }
  }
  
  fun setText(text: String) {
    _newComment.update { text }
  }
  
  fun save(comment: Comment) {
    scope.launch { 
      _comments.load {
        commentService.save(comment)
        _newComment.update { "" }
        it.orEmpty() + comment
      }
    }  
  }
  
  fun loadMore() {
    scope.launch { 
      _comments.load { comments.value.orEmpty() + loadComments() }
    }
  }

  private suspend fun loadComments(): List<Comment> {
    val comments = commentService.comments(contentId, pagination())
    _hasMore.update { comments.size >= itemsPerRequest }
    scope.launch { 
      _authors.update { authors -> 
        authors + userService.users(comments.map { it.authorId }.filterTo(HashSet()) { it !in authors.keys }).associateBy { it.id } 
      }
    }
    return comments
  }

  private fun pagination(): Pagination {
    val lastComment = comments.value.orEmpty().lastOrNull()
    return Pagination(Fields(lastDate = lastComment?.createdAt), RECENT, itemsPerRequest)
  }
  
  companion object {
    const val itemsPerRequest = Requests.maxItems
    
    @Composable
    fun create(contentId: ContentId): CommentViewModel {
      val scope = rememberCoroutineScope()
      val commentService = LocalDependencies.current.commentService
      val userService = LocalDependencies.current.userService
      return remember { CommentViewModel(contentId, commentService, userService, scope) }
    }
  }
}