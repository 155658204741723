package tripper.components

import androidx.compose.runtime.*
import kotlinx.browser.window
import org.lighthousegames.logging.KmLogging
import org.w3c.dom.Window
import org.w3c.dom.events.Event
import tripper.BackendLogger
import tripper.Dependencies
import tripper.JsLocalStorage
import tripper.LocalDependencies
import tripper.lib.js.import
import tripper.navigation.JsNavigation
import tripper.navigation.Navigator
import tripper.notifications.Notifications
import tripper.search.SearchResult
import tripper.trips.EditTrip
import tripper.trips.TripView
import tripper.trips.Trips
import tripper.users.Profile
import tripper.users.UserRef

@Composable
fun Application(
  dependencies: Dependencies = Dependencies.create(JsLocalStorage(), JsNavigation()),
) {
  var cssLoaded by remember { mutableStateOf(false) }
  LaunchedEffect(Unit) {
    import("./styles/app.scss").then<dynamic> { cssLoaded = true }
    KmLogging.addLogger(BackendLogger(dependencies.httpClient, this))
  }
  val isMobile = rememberIsMobile()
  CompositionLocalProvider(
    LocalDependencies provides dependencies,
    CssLoaded provides cssLoaded,
    IsMobile provides isMobile,
  ) {

    GlobalExceptionHandler()
    Notifications()

    Navigator { page, arguments, self ->
      Page(page) {
        when (page) {
          home -> trips.open()
          createTrip -> EditTrip(null, self!!)
          editTrip -> EditTrip(arguments["id"], self!!)
          trip -> TripView(arguments["id"], scrollAnchor, scrollToComments = "comments" in arguments)
          trips -> Trips()
          profile -> Profile(arguments.optional("nickname")?.let(UserRef::Nickname) ?: UserRef.Id(arguments["id"]))
          search -> SearchResult(arguments["q"])
          followings -> Trips(followings = true)
          favorites -> Trips(favorites = true)
          notFound -> NotFound()
          else -> NotFound()
        }
      }
    }
  }
}

val CssLoaded = compositionLocalOf { false }

val IsMobile = compositionLocalOf { false }

@Composable
fun isMobile() = IsMobile.current

@Composable
fun isDesktop() = !isMobile()

@Composable
private fun rememberIsMobile(): Boolean {
  var isMobile by remember { mutableStateOf(window.detectIsMobile()) }
  DisposableEffect(Unit) {
    val callback: (Event) -> Unit = { isMobile = window.detectIsMobile() }
    window.addEventListener("resize", callback)
    onDispose { window.removeEventListener("resize", callback) }
  }
  return isMobile
}

fun Window.detectIsMobile() = window.innerWidth < 768
