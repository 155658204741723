package tripper.comments

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import tripper.users.UserRef
import kotlin.jvm.JvmInline

@Serializable
data class Comment(
  val contentId: ContentId,
  val authorId: UserRef.Id,
  val text: String,
  val createdAt: Instant,
)

@JvmInline
@Serializable
value class ContentId(val value: String) {
  constructor(id: String, type: Type): this("$type:$id")

  override fun toString() = value

  enum class Type { TRIP }
}