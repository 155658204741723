package tripper.domain

import kotlinx.datetime.Instant
import tripper.validation.Restrictions.Requests

data class Pagination(val fields: Fields, val sort: Sort, private val _limit: Int) {
  val limit get() = _limit.coerceAtMost(Requests.maxItems)
}

enum class Sort { RECENT, RELEVANT }

data class Fields(
  val lastDate: Instant? = null,
  val page: Int = 0,
)