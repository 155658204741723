package tripper.users

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import tripper.Loading
import tripper.Loading.None
import tripper.LocalDependencies
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.rememberCoroutineScope
import tripper.load

class UserViewModel(id: UserRef.Id, userService: UserService, scope: SafeCoroutineScope) {
  private val _user = MutableStateFlow<Loading<User>>(None)
  val user = _user.asStateFlow()
  
  init {
    scope.launch { 
      _user.load { userService.user(id) }
    }
  }
  
  companion object {
    @Composable
    fun remember(id: UserRef.Id): UserViewModel {
      val userService = LocalDependencies.current.userService
      val scope = rememberCoroutineScope()
      return remember { UserViewModel(id, userService, scope) }
    }
  }
}