package tripper.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.Main
import tripper.ScrollAnchor
import tripper.navigation.Pages
import tripper.rememberScrollAnchor

@Composable
fun Page(page: Pages.Page, content: @Composable PageContext.() -> Unit) {
  Header(page)

  Main({ classes("body") }) {
    val scrollAnchor = ElementScopeWrapper(this).rememberScrollAnchor()
    PageContext(scrollAnchor).content()

    RequestLoadingBar()
  }
}

class PageContext(val scrollAnchor: ScrollAnchor)