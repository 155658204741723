package tripper

import androidx.compose.runtime.*
import io.ktor.client.*
import tripper.auth.AuthClient
import tripper.auth.AuthService
import tripper.auth.AuthViewModel
import tripper.comments.CommentService
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.rememberCoroutineScope
import tripper.dto.Locale
import tripper.favorites.FavoriteService
import tripper.files.FileClient
import tripper.followings.FollowingService
import tripper.followings.FollowingsViewModel
import tripper.geocoding.GeocodingService
import tripper.likes.LikeService
import tripper.navigation.NavigatorViewModel
import tripper.navigation.Pages
import tripper.navigation.PlatformNavigation
import tripper.notifications.NotificationViewModel
import tripper.search.SearchService
import tripper.search.SearchViewModel
import tripper.trips.TripService
import tripper.users.SelfViewModel
import tripper.users.User
import tripper.users.UserService

class Dependencies(
  scope: SafeCoroutineScope,
  platformNavigation: PlatformNavigation,
  val storage: Storage,
  val localeViewModel: LocaleViewModel,
  val notificationViewModel: NotificationViewModel,
  val requestViewModel: RequestViewModel = RequestViewModel(),
  val authViewModel: AuthViewModel = AuthViewModel(storage, scope),
  val navigatorViewModel: NavigatorViewModel = NavigatorViewModel(platformNavigation, scope),

  val httpClient: HttpClient = HttpClient(httpConfig(authViewModel, requestViewModel, localeViewModel.locale)),
  val pages: Pages = Pages(navigatorViewModel),

  val authClient: AuthClient = AuthClient(httpClient, authViewModel),
  val fileClient: FileClient = FileClient(httpClient),

  val authService: AuthService = AuthService(authClient),
  val tripService: TripService = TripService(httpClient),
  val userService: UserService = UserService(httpClient),
  val searchService: SearchService = SearchService(httpClient),
  val commentService: CommentService = CommentService(httpClient),
  val followingService: FollowingService = FollowingService(httpClient),
  val likeService: LikeService = LikeService(httpClient),
  val favoriteService: FavoriteService = FavoriteService(httpClient),
  val geocodingService: GeocodingService = GeocodingService(httpClient),

  val followingsViewModel: FollowingsViewModel = FollowingsViewModel(followingService, authViewModel, scope),
  val searchViewModel: SearchViewModel = SearchViewModel(searchService, scope),
  val selfViewModel: SelfViewModel = SelfViewModel(userService, authViewModel, scope),
) {
  
  fun init() {
    authViewModel.reduce(authService::reduce)
  }
  
  fun clean() {
    httpClient.close()
  }
  
  companion object {
    @Composable 
    fun create(storage: Storage, platformNavigation: PlatformNavigation): Dependencies {
      val localeViewModel = remember { LocaleViewModel() }
      val notificationViewModel = remember { NotificationViewModel() }
      val scope = rememberCoroutineScope(notificationViewModel, localeViewModel)
      val dependencies = remember { Dependencies(scope, platformNavigation, storage, localeViewModel, notificationViewModel) }
      
      DisposableEffect(Unit) {
        dependencies.init()
        onDispose { dependencies.clean() }
      }
      return dependencies
    }
  }
}

val LocalDependencies = compositionLocalOf<Dependencies> { error("No dependencies provided") }

@Composable
fun rememberLocale(): Locale {
  val locale by LocalDependencies.current.localeViewModel.locale.collectAsState()
  return locale
}

@Composable
fun rememberMessages(locale: Locale = rememberLocale()): Messages {
  return Messages(locale.language)
}

@Composable
fun rememberIsLoggedIn(): Boolean {
  val isLoggedIn by LocalDependencies.current.authViewModel.isLoggedIn.collectAsState()
  return isLoggedIn
}

@Composable
fun rememberSelfLoading(): Loading<User?> {
  val self by LocalDependencies.current.selfViewModel.self.collectAsState()
  return self
}