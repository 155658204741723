package tripper


abstract class ApplicationException(override val message: String? = null): Exception()
data class ClientException(override val message: String? = null, override val cause: Throwable? = null): ApplicationException(message)
data class InternalException(override val message: String? = null, override val cause: Throwable? = null): ApplicationException(message)
data class UnauthorizedException(override val message: String? = null, override val cause: Throwable? = null): ApplicationException(message)
data class NoAccessException(override val message: String? = null, override val cause: Throwable? = null): ApplicationException(message)
data class NotFoundException(override val message: String? = null, override val cause: Throwable? = null): ApplicationException(message)
data class LimitExceededException(override val message: String? = null, override val cause: Throwable? = null): ApplicationException()

open class ValidationException(override val message: String? = null): ApplicationException(message)