package tripper

import io.ktor.client.*
import io.ktor.client.request.*
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import org.lighthousegames.logging.LogLevel
import org.lighthousegames.logging.LogLevel.*
import org.lighthousegames.logging.Logger

class BackendLogger(private val client: HttpClient, private val scope: CoroutineScope): Logger {
  override fun debug(tag: String, msg: String) = log(Debug, tag, msg)
  override fun error(tag: String, msg: String, t: Throwable?) = log(Error, tag, msg, t)
  override fun info(tag: String, msg: String) = log(Info, tag, msg)
  override fun verbose(tag: String, msg: String) = log(Verbose, tag, msg)
  override fun warn(tag: String, msg: String, t: Throwable?) = log(Warn, tag, msg, t)

  override fun isLoggingDebug(): Boolean = false
  override fun isLoggingError(): Boolean = true
  override fun isLoggingInfo(): Boolean = true
  override fun isLoggingVerbose(): Boolean = false
  override fun isLoggingWarning(): Boolean = true
  
  private fun log(level: LogLevel, tag: String, msg: String, t: Throwable? = null) {
    scope.launch {
      try {
        client.post("log") { setBody(LogEvent(level, tag, msg, t?.stackTraceToString())) }
      } catch (e: Throwable) {
        e.printStackTrace()
      }
    }
  }
}

@Serializable
data class LogEvent(
  val level: LogLevel,
  val tag: String,
  val message: String,
  val stackTrace: String?,
)