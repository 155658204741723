package tripper.domain

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

@Serializable
data class FileMeta(@SerialName("_key") val id: String) {
  @Transient val src = Url("/api/files/$id")
}
