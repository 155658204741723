package tripper.components

import androidx.compose.runtime.*
import androidx.compose.ui.Alignment.Companion.TopCenter
import androidx.compose.ui.unit.IntOffset
import org.jetbrains.compose.web.dom.Span
import org.w3c.dom.HTMLElement

@Composable
fun Tooltip(tooltip: @Composable () -> Unit, content: @Composable () -> Unit) {
  Span {
    var anchorHeight by remember { mutableStateOf<Int?>(null) }
    anchorHeight?.let { anchorHeight ->
      Popup("tooltip", alignment = TopCenter, IntOffset(0, anchorHeight + 5)) {
        tooltip()
      }
    }
    Span({
      onMouseOver { anchorHeight = (it.target as HTMLElement).offsetHeight }
      onMouseOut { anchorHeight = null }
    }) {
      content()
    }
  }
}