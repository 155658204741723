package tripper.auth

import kotlinx.coroutines.flow.*
import tripper.Storage
import tripper.Storage.Key.ACCESS_TOKEN
import tripper.auth.dto.Token
import tripper.auth.dto.VkAuth
import tripper.coroutines.SafeCoroutineScope

open class AuthViewModel(storage: Storage, private val scope: SafeCoroutineScope) {
  private val tokenInternal = MutableStateFlow(storage[ACCESS_TOKEN]?.let(::Token))
  private val isLoggedInInternal = MutableStateFlow(tokenInternal.value != null)
  private val actions = MutableSharedFlow<AuthAction>()
  
  open val token: StateFlow<Token?> = tokenInternal
  open val isLoggedIn: MutableStateFlow<Boolean> = isLoggedInInternal
  
  init {
    scope.launch {
      token.collectLatest { token ->
        isLoggedInInternal.update { token != null }
        if (token != null) storage[ACCESS_TOKEN] = token.value
        else storage.remove(ACCESS_TOKEN)
      }
    }
  }
  
  open fun login(accessToken: Token) {
    tokenInternal.update { accessToken }
  }

  open fun logout() {
    tokenInternal.update { null }
  }

  open fun onAction(action: AuthAction) {
    scope.launch { 
      actions.emit(action)
    }
  }

  open fun reduce(reducer: suspend (AuthAction) -> Unit) {
    scope.launch {
      actions.collectLatest {
        reducer(it)
      }
    }
  }
}

sealed interface AuthAction {
  data object Logout: AuthAction
  data class LoginVk(val auth: VkAuth): AuthAction
  data class LoginGoogle(val token: String): AuthAction
}