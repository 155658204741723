package tripper.followings

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import org.jetbrains.compose.web.dom.A
import tripper.*
import tripper.components.Text
import tripper.users.User

@Composable
fun FollowButton(
  author: User,
  modifier: Modifier = emptyModifier(),
  viewModel: FollowingsViewModel = LocalDependencies.current.followingsViewModel,
  messages: Messages = rememberMessages(),
) {
  val self = rememberSelfLoading().untilLoaded(null) ?: return
  val (followings) = viewModel.followings.collectAsState()
  if (author.id == self.id) return
  val following = followings.orEmpty().find { it.authorId == author.id }
  A(attrs = {
    classes("following")
    onClick {
      if (following != null) {
        viewModel.unfollow(following)
      } else {
        viewModel.follow(author, self)
      }
    }
    modifier.modify(this)
  }) {
    if (following != null) {
      Text(messages.unfollow())
    } else {
      Text(messages.follow())
    }
  }
}