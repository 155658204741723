package tripper

import androidx.compose.runtime.NoLiveLiterals
import kotlinx.browser.document
import org.jetbrains.compose.web.renderComposable
import tripper.components.Application
import tripper.lib.Libs

@NoLiveLiterals
fun main() {
  document.addEventListener("DOMContentLoaded", {
    Libs.init()
    renderComposable("root") {
      Application()
    }
  })
}