package tripper.dto

import tripper.InternalException

data class Locale(val language: Language, val region: String?) {
  val languageTag = buildString { 
    append(language.code)
    if (region != null) append("-$region")
  }
  
  companion object {
    fun parseLanguageTag(tag: String): Locale {
      val codes = tag.split('-')
      return Locale(Language.fromCode(codes[0]), if (codes.size > 1) codes[1] else null)
    }
  }
}

enum class Language(val code: String) {
  RU("ru"),
  EN("en");
  
  companion object {
    private val languageByCode = Language.entries.associateBy { it.code }
    fun fromCode(code: String): Language {
      return languageByCode[code] ?: throw InternalException("Unsupported language code: $code")
    }
  }
}