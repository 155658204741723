package tripper.files

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.http.ContentDisposition.Parameters.FileName
import tripper.domain.FileMeta

open class FileClient(private val client: HttpClient) {
  
  open suspend fun upload(file: ByteArray, name: String, type: ContentType): FileMeta {
    return client.post("files") {
      setBody(file)
      contentType(type)
      header("Content-Disposition", ContentDisposition.Attachment.withParameter(FileName, name))
    }.body()
  }

  open suspend fun delete(meta: FileMeta) {
    client.delete("files/${meta.id}")
  } 
}