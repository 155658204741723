package tripper.components

import androidx.compose.runtime.Composable
import tripper.Modifier
import tripper.emptyModifier
import tripper.modifier
import tripper.plus

@Composable
fun Spacer(modifier: Modifier = emptyModifier()) {
  Row(modifier { classes("spacer") } + modifier) {}
}