package tripper.trips

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import tripper.Messages
import tripper.components.InfiniteScrollList
import tripper.rememberMessages

@Composable
fun InfiniteScrollTrips(
  trips: List<Trip>,
  loadMore: () -> Unit,
  messages: Messages = rememberMessages(),
  tripContent: @Composable (Trip) -> Unit,
) {
  if (trips.isEmpty()) {
    P({ classes("empty-result") }) {
      Text(messages.noTrips())
    }
  } else {
    InfiniteScrollList(
      items = trips,
      key = Trip::id,
      loadMore = loadMore,
      loadMoreIndex = (trips.size - 2).coerceAtLeast(0),
      itemContent = tripContent,
    )
  }
}