package tripper.components

import androidx.compose.runtime.Composable
import tripper.Messages
import tripper.emptyModifier
import tripper.rememberMessages
import tripper.tag

@Composable
fun NotFound(messages: Messages = rememberMessages()) {
  Row(emptyModifier().tag("not-found")) {
    Text(messages.pageNotFound())
  }
}