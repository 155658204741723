package tripper.notifications

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import tripper.LocalDependencies

@Composable
fun Notifications(viewModel: NotificationViewModel = LocalDependencies.current.notificationViewModel) {  
  val notification = viewModel.notification.collectAsState().value
  
  if (notification != null) NotificationPopup(notification.text, notification.duration)
}